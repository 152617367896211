import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Container, Row, Col } from 'react-bootstrap';
import ImageComponent from '../Image/ImageComponent';

const Clients = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="clients">
      <Container>
        <div className="client-wrapper">
          <Row>
            <Col md={6} sm={12} className="mb-4">
              <Fade
                right={isDesktop}
                bottom={isMobile}
                duration={1000}
                delay={1000}
                distance="30px"
              >
                <div className="client-wrapper__image first-image">
                  <Tilt
                    options={{
                      reverse: false,
                      max: 8,
                      perspective: 1000,
                      scale: 1,
                      speed: 300,
                      transition: true,
                      axis: null,
                      reset: true,
                      easing: 'cubic-bezier(.03,.98,.52,.99)',
                    }}
                  >
                    <div data-tilt className="thumbnail rounded">
                      <ImageComponent alt="" filename="steveCohen.png" />
                    </div>
                  </Tilt>
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12} className="pl-md-5">
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={700} distance="30px">
                <div className="client-wrapper__text">
                  <h2>Portfolio Managers / Investors</h2>
                  <p>
                    As Head Performance Coach at Steve Cohen’s Point 72 Asset Management, Dr. Gio
                    has logged over 5,000 hours coaching some of the most sophisticated investors in
                    the world. He has coached PM’s to earn over $5 billion dollars of P&L. He knows
                    the psychology of what it takes to navigate tough markets, remain steady through
                    draw downs, and be self-critical so as to learn from mistakes and generate
                    record P&L years.
                  </p>
                </div>
              </Fade>
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
                <div className="client-wrapper__text">
                  <h2>Executive Coaching</h2>
                  <p>
                    Dr. Gio Valiante has coached executives at the highest levels across many
                    industries including sports, finance, sales, start-ups, and business. Imagine
                    what you can achieve having Dr. Gio in your corner as a trusted thought partner
                    whose main goal is your success.
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} className="mb-4 order-md-2">
              <Fade
                right={isDesktop}
                bottom={isMobile}
                duration={1000}
                delay={1000}
                distance="30px"
              >
                <div className="client-wrapper__image second-image">
                  <Tilt
                    options={{
                      reverse: false,
                      max: 8,
                      perspective: 1000,
                      scale: 1,
                      speed: 300,
                      transition: true,
                      axis: null,
                      reset: true,
                      easing: 'cubic-bezier(.03,.98,.52,.99)',
                    }}
                  >
                    <div data-tilt className="thumbnail rounded">
                      <ImageComponent alt="" filename="hireImage2.png" />
                    </div>
                  </Tilt>
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12} className="order-md-1 pr-md-5">
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={700} distance="30px">
                <div className="client-wrapper__text">
                  <h2>Athletes</h2>
                  <p>
                    Dr. Gio has coached some of the worlds best athletes in the NFL, NBA, and on the
                    PGA Tour. He was the head performance coach for the Buffalo Bills when they
                    broke their 17 year playoff drought. He has worked with Doc Rivers, the Orlando
                    Magic, and various individual players. On the PGA Tour, he was the winningest
                    sport psychologist in the world, having helped players generate over 50
                    professional wins. At some point in their careers, he worked with all three
                    medal winners of the 2016 Olympics. Henrik Stenson, Matt Kuchar, Justin Rose and
                    legendary hockey player Mike Modano.
                  </p>
                </div>
              </Fade>
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
                <div className="client-wrapper__text">
                  <h2>Coaching for coaches</h2>
                  <p>
                    Head coaches need a trusted thought partner who is honest, objective, and can
                    see the big picture. Dr. Gio has worked in all parts of organizations, and can
                    help you as a head coach deal with the unique nature of the navigating a team
                    through a tough season while managing both up and delegating down.
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} className="mb-4">
              <Fade
                right={isDesktop}
                bottom={isMobile}
                duration={1000}
                delay={1000}
                distance="30px"
              >
                <div className="client-wrapper__image third-image">
                  <Tilt
                    options={{
                      reverse: false,
                      max: 8,
                      perspective: 1000,
                      scale: 1,
                      speed: 300,
                      transition: true,
                      axis: null,
                      reset: true,
                      easing: 'cubic-bezier(.03,.98,.52,.99)',
                    }}
                  >
                    <div data-tilt className="thumbnail rounded">
                      <ImageComponent alt="" filename="motivationalTalks.png" />
                    </div>
                  </Tilt>
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12} className="pl-md-5 d-md-flex align-items-center">
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={700} distance="30px">
                <div className="client-wrapper__text">
                  <h2>Motivational Talks</h2>
                  <p>
                    Dr. Gio is a powerful speaker with years of experience. He has given over 100
                    keynote speeches to organizations ranging from Coca Cola, and Red Bull to
                    Symantec, At&T. In finance he has been keynote speaker to UBS, Bank of America,
                    MFA, and CFA. In 2016 he gave the keynote speech to the 100 Women in Hedge
                    Funds: “How the Close the Confidence Gap.”
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <AniLink cover direction="up" to="/contact" bg="#3486fb">
                <span className="cta-btn">BOOK NOW</span>
              </AniLink>
            </Fade>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Clients;
