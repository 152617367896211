import React from 'react';
import Layout from '../components/Layout';
import Clients from '../components/Clients';

function ResultsPage() {
  return (
    <Layout
      title="Dr. Gio Valiante | Results"
      description="Dr. Gio Valiante’s results speak for themselves. With over 50 professional and amateur wins on the PGA tour alone, Dr. Gio has set an unprecedented standard in achievement."
    >
      <Clients />
    </Layout>
  );
}

export default ResultsPage;
